import React, { useState } from 'react';
import './txixtstyle.css';
import logo from './txixtassets/txixtLogoixt.gif';
import aboutData from './txixtAbout.json';
import appData from './txixtApps.json';
import TxixtModal from './TxixtModal';

function TxixtApp() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalItem, setModalItem] = useState(null);


    const handleItemClick = (item) => {
        setIsModalOpen(true);
        setModalItem(item);
    }

    return (
        <>
            <div className="container">
                <div className="onecentered-bg"></div>
                <div
                    className="logo"
                    tabIndex={0}
                    onClick={() => handleItemClick(aboutData)}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') handleItemClick(aboutData);
                    }}
                >
                    <img id="logo-img" src={logo} alt="txixt" />
                </div>
            </div>
            <div className="header">
                <div className="left-cell">
                    <p>insipid design.<br />sipid content.</p>
                </div>
                <div className="menu-overlay" id="menuOverlay" onClick={() => setMenuOpen(!menuOpen)}></div>
                <div className="right-cell">
                    <div
                        className={`hamburger ${menuOpen ? 'active' : ''}`}
                        id="hamburgerMenu"
                        role="button" 
                        aria-expanded={menuOpen}
                        aria-controls="menuOverlay"
                        tabIndex={0}
                    >
                        {appData.apps.map((app, index) => (
                            <div key={index} onClick={() => handleItemClick(app)}>
                                <div className="icon" data-title={app.title}>
                                    <img src={process.env.PUBLIC_URL + 'txixtassets/' + app.icon_url} alt={app.icon_alt} />
                                    <span>{app.title}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <TxixtModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                item={modalItem}
            />
        </>
    );
}

export default TxixtApp;
