import React, { useState } from 'react';
import './txixtstyle.css';
import TxixtContactModal from './txixtContact';

function TxixtModal({ isOpen, onClose, item }) {
    const [view, setView] = useState('default');

    if (!isOpen || !item) return null;

    const textFormat = (text) => {
        // Same formatting function as you've defined.
        let formattedText = text;
        formattedText = formattedText.replace(/\n/g, '<br>');
        formattedText = formattedText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');
        formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');
        return { __html: formattedText }; // This is important as we'll use dangerouslySetInnerHTML
    };

    const handleClose = () => {
        setView('default'); // Reset the view to 'default'
        onClose(); // Call the onClose prop function
    };

    const renderTitle = () => {
        switch (view) {
            case 'terms': 
                if (item.title != "what it is") {
                    return <>{item.title + ' terms of service'}</>;
                } else {
                    return <>general terms</>
                };
            case 'privacy': 
                if (item.title != "what it is") {
                    return <>{item.title + ' privacy policy'}</>;
                } else {
                    return <>general privacy</>
                };
            case 'contact':
                return <> {'contact us'}</>;
            default: 
                return <>{item.title}</>;
        }
    };

    const renderInternalLinks = () => {
        const internalLinks = item.internal_links;
        // Check if there are any internal links.
        if (!internalLinks || Object.keys(internalLinks).length === 0) {
          return <p className='modal-link'><b>coming soon...</b></p>;
        }
        
        return Object.entries(internalLinks).map(([key, text], index) => (
          <div key={index} className='modal-link'>
            <a href="#" onClick={(e) => { e.preventDefault(); setView(key); }}>
              {key}
            </a>
          </div>
        ));
    };
    
    const renderExternalLinks = () => {
        const externalLinks = item.external_links;
        if (!externalLinks) {
          return; // or handle this case as needed
        }
        
        return Object.entries(externalLinks).map(([key, link], index) => (
          <div key={index} className='modal-link'>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {key}
            </a>
          </div>
        ));
    };

    const renderTerms = () => {
        return ( <>
                <p dangerouslySetInnerHTML={textFormat(item.internal_links.terms)} />
                <br/><br/>
                <a href="#" onClick={(e) => { e.preventDefault(); setView('default'); }}>back</a>
            </> 
        )
    };

    const renderPrivacy = () => {
        return ( <>
                <p dangerouslySetInnerHTML={textFormat(item.internal_links.privacy)} />
                <br/>
                <a href="#" onClick={(e) => { e.preventDefault(); setView('default'); }}>back</a>
            </>
        )
    };

    const renderContentBasedOnView = () => {
        switch (view) {
          case 'terms':
            return renderTerms();
          case 'privacy':
            return renderPrivacy();
          case 'contact':
            return <TxixtContactModal isOpen={isOpen} onClose={handleClose} />;
          default:
            return (
              <div>
                <p dangerouslySetInnerHTML={textFormat(item.text)} />
                <p>{renderInternalLinks()}</p>
                <p>{renderExternalLinks()}</p>
              </div>
            );
        }
    };

    return (
        <div className="modal">
            <div className="modal-body">
                <div className="close-span">
                    <button className="close-btn" onClick={handleClose}>x</button>
                </div>
                <div className="modal-icon-title">
                    <img src={process.env.PUBLIC_URL + 'txixtassets/' + item.icon_url} alt={item.icon_alt} className="modal-icon" />
                    <h3>{renderTitle()}</h3>
                </div>
                <div className='modal-content-papi'>
                <div className="modal-content">
                   {renderContentBasedOnView()}
                </div>
                </div>
            </div>
        </div>
    );
}

export default TxixtModal;
