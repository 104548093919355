import React, { useState } from 'react';
import './txixtstyle.css';




function TxixtContactModal({ isOpen, onClose, setView }) {
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    const apiUrl = 'https://p2ctyn0xp9.execute-api.us-east-1.amazonaws.com/contactbeta/contact';
    const name  = 'sent from txixt.com';
    const email = new Date().toISOString(); 
    const telephone = 'who would sent their phone number?';
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            email,
            telephone,
            message
        }),
      });

      const responseData = await response.json();
  
      if (response.ok && responseData.message === 'Email sent successfully') {
        // Reset the form fields and show a success message
        alert('good to hear from you!');
        handleClose()
      } else {
        console.log(responseData.message || 'This form did NOT get submitted, and you should be pissed.');
        handleClose();
      }
    } catch (error) {
        console.log('Error submitting these forms yo');
        handleClose()
    }
  };

  const handleClose = () => {
    setMessage('');
    onClose(); // Call the onClose prop function
};

  if (!isOpen) return null;

  return (
    <div>
        <textarea className='contact-text'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Contact us. Include your name and/or contact info, otherwise your feedback is anonymous."
            aria-label="Your message here."
        />
        <div className='contact-buttons'>                        
        <div role="button" tabIndex="0" onClick={handleClose} onKeyUp={(event) => (event.key === 'Enter' || event.key === ' ' ) && handleClose()}>
            <a href="#" onClick={(e) => e.preventDefault()} aria-hidden="true">Nevermind</a>
        </div>
        <div role="button" tabIndex="0" onClick={handleSubmit} onKeyUp={(event) => (event.key === 'Enter' || event.key === ' ' ) && handleSubmit()}>
            <a href="#" onClick={(e) => e.preventDefault()} aria-hidden="true">Send</a>
        </div>
    </div>
    </div>
  );
}

export default TxixtContactModal;